<template>
  <div>
    <!-- Title -->
    <app-section-title
      :title="t('extranet.dashboard.sections.last_payments')"
      class="mb-3.5"
    />

    <!-- Table -->
    <app-table
      v-if="loading || tableData.length > 0"
      :headers="tableHeaders"
      :data="tableData"
      :loading="loading"
      class="mb-4"
    />

    <!-- No records -->
    <app-card-item
      v-else
      class="p-10 text-center mb-4"
    >
      {{ t('extranet.dashboard.last_payments.none') }}
    </app-card-item>

    <!-- Index link -->
    <router-link
      v-if="!loading"
      :to="{ name: 'finances' }"
      class="app-link text-xl block mx-auto w-fit"
    >
      <span v-if="tableData.length > 0">
        {{ t('extranet.dashboard.last_payments.see_all') }}
      </span>

      <span v-else>
        {{ t('extranet.dashboard.last_payments.see_index') }}
      </span>
    </router-link>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import AppSectionTitle from '@extranet/components/ui/AppSectionTitle.vue'
import AppTable from '@extranet/components/ui/table/AppTable.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import { getAmountWithCurrency } from '@shared/helpers/payment'
import { displayName } from '@shared/helpers/user'

const props = defineProps({
  // Folders JSON API resources
  resources: {
    type: Array,
    default: () => ([]),
  },
  // Is it loading?
  loading: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()

// ---------- TABLE ----------

const tableHeaders = [
  { label: t('validation.attributes.payer') },
  { label: t('validation.attributes.net_amount') },
]

const tableData = computed(() => (
  props.resources.map((payment) => ({
    payer: displayName(payment.resource.relationships.payer),
    net_amount: getAmountWithCurrency(payment.resource.attributes.net_amount, payment.resource.attributes.currency),
  }))
))
</script>
