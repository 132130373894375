<template>
  <div>
    <!-- Title -->
    <app-section-title
      :title="t('extranet.dashboard.sections.last_travellers')"
      class="mb-3.5"
    />

    <!-- Loading -->
    <template
      v-if="loading"
    >
      <traveller-folder-card-empty
        v-for="(i, key) in 5"
        :key="key"
        class="mb-5"
      />
    </template>

    <!-- Folders -->
    <div
      v-else-if="resources.length > 0"
      class="mb-4"
    >
      <app-card-item
        v-for="(folder, i) in resources"
        :key="i"
        class="p-4 mb-3"
      >
        <traveller-folder-card
          :resource="folder"
        />
      </app-card-item>

      <router-link
        :to="{ name: 'travellers' }"
        class="app-link text-xl block mx-auto w-fit"
      >
        {{ t('extranet.dashboard.last_traveller_folders.see_all') }}
      </router-link>
    </div>

    <!-- No folders -->
    <template v-else>
      <app-card-item class="p-10 text-center mb-4">
        {{ t('extranet.dashboard.last_traveller_folders.none') }}
      </app-card-item>

      <router-link
        :to="{ name: 'travellers' }"
        class="app-link text-xl block mx-auto w-fit"
      >
        {{ t('extranet.dashboard.last_traveller_folders.take_care') }}
      </router-link>
    </template>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import AppSectionTitle from '@extranet/components/ui/AppSectionTitle.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import TravellerFolderCardEmpty from '@extranet/components/resources/traveller_folder/TravellerFolderCardEmpty.vue'
import TravellerFolderCard from '@extranet/components/resources/traveller_folder/TravellerFolderCard.vue'

defineProps({
  // Folders JSON API resources
  resources: {
    type: Array,
    default: () => ([]),
  },
  // Is it loading?
  loading: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()
</script>
