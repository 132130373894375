export default function useReservation() {
  /**
   * Get CSS style for status
   *
   * @param state
   * @returns {string}
   */
  function statusBadgeDesign(state) {
    switch (state) {
      case 'confirmed':
        return 'success'
      case 'pending':
        return 'warning'
      case 'canceled':
        return 'danger'
      default:
        return 'default'
    }
  }

  return {
    statusBadgeDesign,
  }
}
