<template>
  <div class="flex items-center">
    <app-button
      v-if="resource.authorizations.view"
      feature="show"
      emphasis="low"
      hide-text
      :hide-icon="false"
      class="mx-1"
      @click="handleShow(resource.id)"
    />
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'

import AppButton from '@shared/components/ui/AppButton.vue'

defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const router = useRouter()

// ---------- SHOW ----------

function handleShow(id) {
  router.push({
    name: 'show-claims',
    params: { id },
  })
}
</script>
