<template>
  <app-card-item
    class="relative text-white font-bold overflow-hidden cursor-pointer"
    @click="handleNavigate"
  >
    <!-- Blurred image container -->
    <div
      class="bg-cover bg-center h-40 blur-[2px]"
      :style="`background-image: url(${backgroundImage});`"
    />

    <!-- Card content centered on blurred container -->
    <div class="flex items-center flex-col absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
      <div class="flex items-center text-6xl mb-4">
        <span class="mr-3">
          {{ formattedValue }}
        </span>

        <app-icon
          v-bind="iconProps"
        />
      </div>

      <span class="text-xl whitespace-nowrap">
        {{ label }}
      </span>
    </div>
  </app-card-item>
</template>

<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import AppIcon from '@shared/components/ui/AppIcon.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'

const props = defineProps({
  // Stat card label
  label: {
    type: String,
    required: true,
  },
  // Stat card value
  value: {
    type: Number,
    default: 0,
  },
  // Card's background image URL
  backgroundImage: {
    type: String,
    default: null,
  },
  // AppIcon component's props
  iconProps: {
    type: Object,
    default: () => ({}),
  },
  // Route name
  routeName: {
    type: String,
    required: true,
  },
})

const formattedValue = computed(() => (
  props.value.toLocaleString()
))

// ---------- NAVIGATION ----------

const router = useRouter()

function handleNavigate() {
  router.push({
    name: props.routeName,
  })
}
</script>
