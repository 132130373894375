export default function useClaim() {
  /**
   * Get CSS style for status
   *
   * @param state
   *
   * @returns {string}
   */
  function statusBadgeDesign(state) {
    switch (state) {
      case 'pending':
        return 'default'
      case 'missing-info':
        return 'warning'
      case 'rejected':
        return 'danger'
      case 'validated':
        return 'success'
      default:
        return 'default'
    }
  }

  return {
    statusBadgeDesign,
  }
}
