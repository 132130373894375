<template>
  <router-link
    :to="{ name: 'show-traveller-folders', params: { id: resource.id } }"
    class="flex items-center"
  >
    <app-rounded-picture
      with-shadow
      :picture-url="imageUrl"
      picture-size="12"
      class="mr-5"
    />

    <div class="flex flex-col truncate">
      <span class="font-bold text-lg truncate">
        {{ userDisplayName }}
      </span>

      <span
        v-if="showEmail"
        class="font-semibold text-xs"
      >
        {{ email }}
      </span>
    </div>
  </router-link>
</template>

<script setup>
import { computed } from 'vue'
import useResource from '@shared/hooks/resources/resource'
import useUser from '@shared/hooks/resources/user'
import AppRoundedPicture from '@shared/components/ui/AppRoundedPicture.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const {
  displayName: userDisplayName,
  email,
  showEmail,
} = useUser({ resource: props.resource.relationships?.traveller })

const {
  getImageUrlFromResource,
} = useResource()

const imageUrl = computed(() => (
  getImageUrlFromResource(props.resource.relationships?.traveller, 'xs')
))
</script>
