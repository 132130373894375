<template>
  <!-- Loading -->
  <tutorial-loading
    v-if="loading"
  />

  <app-card-item
    v-else
    class="p-3 mb-5 flex items-center flex-col"
    background-classes="bg-gray-200 text-center"
  >
    <app-icon
      library="coolicon"
      name="flag_outline"
      size="text-4xl"
      class="mb-2"
    />

    <p class="font-bold text-xl mb-4">
      {{ t('tutorials.extranet.dashboard.claim.title') }}
    </p>

    <p class="font-bold">
      {{ t('tutorials.extranet.dashboard.claim.subtitle_1') }}
    </p>

    <p class="font-bold">
      {{ t('tutorials.extranet.dashboard.claim.subtitle_2') }}
    </p>

    <ol class="mt-4 list-decimal list-inside">
      <li>
        {{ t('tutorials.extranet.dashboard.claim.steps.poi_index.part_1') }}

        <router-link
          :to="{ name: 'index-points-of-interest' }"
          class="app-link"
        >
          {{ t('tutorials.extranet.dashboard.claim.steps.poi_index.part_2') }}
        </router-link>
      </li>

      <li>
        {{ t('tutorials.extranet.dashboard.claim.steps.filters') }}
      </li>

      <!-- eslint-disable vue/no-v-html -->
      <li v-html="t('tutorials.extranet.dashboard.claim.steps.exist')" />

      <!-- eslint-disable vue/no-v-html -->
      <li v-html="t('tutorials.extranet.dashboard.claim.steps.dont_exist')" />

      <li>
        {{ t('tutorials.extranet.dashboard.claim.steps.claim_form') }}
      </li>

      <li>
        {{ t('tutorials.extranet.dashboard.claim.steps.claim_validation') }}
      </li>
    </ol>
  </app-card-item>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppIcon from '@shared/components/ui/AppIcon.vue'
import TutorialLoading from '@extranet/components/dashboard/shared/tutorial/TutorialLoading.vue'

const { t } = useI18n()

defineProps({
  // Is it loading?
  loading: {
    type: Boolean,
    default: false,
  },
})
</script>
