<template>
  <teleport-wrapper to="#mainTitle">
    {{ t('breadcrumbs.dashboard.dashboard') }}
  </teleport-wrapper>

  <!-- Display dashboard depending on permissions -->
  <dashboard-butler
    v-if="dashboardButlerAccess"
  />

  <dashboard-manager
    v-else-if="dashboardManagerAccess"
  />

  <dashboard-hotelier
    v-else-if="dashboardHotelierAccess"
  />

  <dashboard-tourist-office
    v-else-if="dashboardTouristOfficeAccess"
  />

  <dashboard-tourist-office-employee
    v-else-if="dashboardTouristOfficeEmployeeAccess"
  />

  <app-alert
    v-else
    design="warning"
    :title="t('dashboard.no_dashboard.title')"
  >
    <p class="mt-2">
      {{ t('dashboard.no_dashboard.content') }}
    </p>

    <p class="mt-2">
      {{ t('dashboard.no_dashboard.contact.text') }}

      <router-link
        :to="{ name: 'index-support-requests' }"
        class="app-link"
      >
        {{ t('dashboard.no_dashboard.contact.link') }}
      </router-link>
    </p>
  </app-alert>

  <cookies-warning-modal
    v-if="!getCookieSetting('gmaps')"
  />
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import useAccess from '@extranet/hooks/access'
import useCookies from '@shared/hooks/cookies'
import AppAlert from '@shared/components/ui/AppAlert.vue'
import CookiesWarningModal from '@shared/components/cookies/CookiesWarningModal.vue'
import DashboardButler from '@extranet/components/dashboard/DashboardButler.vue'
import DashboardManager from '@extranet/components/dashboard/DashboardManager.vue'
import DashboardHotelier from '@extranet/components/dashboard/DashboardHotelier.vue'
import DashboardTouristOffice from '@extranet/components/dashboard/DashboardTouristOffice.vue'
import DashboardTouristOfficeEmployee from '@extranet/components/dashboard/DashboardTouristOfficeEmployee.vue'

const { t } = useI18n()
const { getCookieSetting } = useCookies()

// ---------- ACCESS ----------

const {
  dashboardHotelierAccess,
  dashboardManagerAccess,
  dashboardButlerAccess,
  dashboardTouristOfficeAccess,
  dashboardTouristOfficeEmployeeAccess,
} = useAccess()
</script>
