<template>
  <!-- check if links is greather than 3, because it contains at least : previous link, current link, next link -->
  <ul
    v-if="links.length > 3"
    class="flex justify-center font-bold"
  >
    <li
      v-for="(link, i) in links"
      v-show="displayButton(link)"
      :key="i"
      :class="`text-sm mx-1 p-1 ${link.active ? 'active' : ''} ${mdDisplayedButton(link) ? 'block' : 'md:block hidden'}`"
    >
      <div
        :class="`${link.url ? 'cursor-pointer' : '' }`"
        @click="handleChangePage(link)"
      >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="link.label" />
      </div>
    </li>
  </ul>
</template>

<script setup>
import { first, last } from 'lodash'

import useURL from '@shared/hooks/url'

const props = defineProps({
  // Array of pagination links
  // (generated by laravel paginate())
  links: {
    type: Array,
    required: true,
  },
  // Callback for page change
  changePageCallback: {
    type: Function,
    default: () => ({}),
  },
  // HTML element to scroll to when changing page
  elementToScrollTo: {
    type: Object,
    default: () => ({}),
  },
})

const { getAllUrlParams } = useURL()

function extractPageNo(url) {
  const params = getAllUrlParams(url)
  return params.page
}

function displayButton(link) {
  return link.url || link.label === '...'
}

// Check if button can be displayed below "md" width
function mdDisplayedButton(link) {
  return (
    link.active // current page
    || last(props.links) === link // next page
    || first(props.links) === link // current page
  )
}

function handleChangePage(link) {
  if (link?.url) {
    // scroll to element when changing page
    if (typeof props.elementToScrollTo.scrollIntoView === 'function') {
      props.elementToScrollTo.scrollIntoView({ behavior: 'smooth' })
    }

    props.changePageCallback(extractPageNo(link.url))
  }
}
</script>

<style scoped>
.active {
  @apply border-t-2 border-theme-500 pt-0.5 text-theme-500
}
</style>
