export default function useURL() {
  /**
   * Extract URL params from an URL
   *
   * @param url
   * @returns {array|*}
   */
  function getAllUrlParams(url) {
    // Get query string from url or null
    let queryString = url ? url.split('?')[1] : null

    // Store the parameters
    const obj = {}

    if (queryString) {
      // Everything after # is not part of query string
      queryString = queryString.split('#')[0]

      // Split query string into its component parts
      const arr = queryString.split('&')

      for (let i = 0; i < arr.length; i += 1) {
        // Separate keys and values
        const a = arr[i].split('=')

        // Set parameter name and value (use 'true' if empty)
        let paramName = a[0]
        let paramValue = typeof (a[1]) === 'undefined' ? true : a[1]

        // Keep case consistent
        paramName = paramName.toLowerCase()
        if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase()

        // If the paramName ends with square brackets, e.g. colors[] or colors[2]
        if (paramName.match(/\[(\d+)?]$/)) {
          // Create key if it doesn't exist
          const key = paramName.replace(/\[(\d+)?]/, '')
          if (!obj[key]) obj[key] = []

          // If it's an indexed array e.g. colors[2]
          if (paramName.match(/\[\d+]$/)) {
            // Get the index value and add the entry at the appropriate position
            const index = /\[(\d+)]/.exec(paramName)[1]
            obj[key][index] = paramValue
          } else {
            // Otherwise add the value to the end of the array
            obj[key].push(paramValue)
          }
        } else if (!obj[paramName]) {
          // If it doesn't exist, create property
          obj[paramName] = paramValue
        } else if (obj[paramName] && typeof obj[paramName] === 'string') {
          // If property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]]
          obj[paramName].push(paramValue)
        } else {
          // Otherwise add the property
          obj[paramName].push(paramValue)
        }
      }
    }

    return obj
  }

  return {
    // Methods
    getAllUrlParams,
  }
}
