<template>
  <div class="p-5 bg-white shadow-lg shadow-theme-500/10 rounded-2lg overflow-x-auto app-scrollbar">
    <table class="w-full md:table-fixed">
      <slot name="colgroups" />

      <app-table-header
        :headers="headers"
      />

      <tbody>
        <app-table-row-empty
          v-if="loading"
          :colspan="headers.length"
          :quantity="quantityToLoad"
        />

        <template
          v-else-if="data.length > 0"
        >
          <app-table-row
            v-for="(row, i) in data"
            :key="i"
            :row="row"
          />
        </template>

        <app-table-no-records
          v-else
          :colspan="headers.length"
        />
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import AppTableHeader from './AppTableHeader.vue'
import AppTableRow from './AppTableRow.vue'
import AppTableRowEmpty from './AppTableRowEmpty.vue'
import AppTableNoRecords from './AppTableNoRecords.vue'

const props = defineProps({
  // Table headers
  headers: {
    type: Array,
    default: () => ([]),
  },
  // Table data (rows)
  data: {
    type: Array,
    default: () => ([]),
  },
  // Is it loading?
  loading: {
    type: Boolean,
    default: true,
  },
})

// Quantity of rows to load
// Use 15 by default, or the quantity of data previously loaded
const quantityToLoad = computed(() => (
  props.data.length === 0 ? 15 : props.data.length
))
</script>
